import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import DigitsClassifier from '../images/DigitsClassifier.png'
import DMesh from '../images/DMesh.png'
import MobiusStrip from '../images/MobiusStrip.png'
import PartialPolar from '../images/PartialPolar.png'
import goMicroservice from '../images/goMicroservice.png'
import goCYOA from '../images/goCYOA.png'


class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Aenean ornare velit lacus, ac varius enim ullamcorper eu. Proin
            aliquam facilisis ante interdum congue. Integer mollis, nisl amet
            convallis, porttitor magna ullamcorper, amet egestas mauris. Ut
            magna finibus nisi nec lacinia. Nam maximus erat id euismod egestas.
            By the way, check out my <a href="#work">awesome work</a>.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
            dapibus rutrum facilisis. Class aptent taciti sociosqu ad litora
            torquent per conubia nostra, per inceptos himenaeos. Etiam tristique
            libero eu nibh porttitor fermentum. Nullam venenatis erat id
            vehicula viverra. Nunc ultrices eros ut ultricies condimentum.
            Mauris risus lacus, blandit sit amet venenatis non, bibendum vitae
            dolor. Nunc lorem mauris, fringilla in aliquam at, euismod in
            lectus. Pellentesque habitant morbi tristique senectus et netus et
            malesuada fames ac turpis egestas. In non lorem sit amet elit
            placerat maximus. Pellentesque aliquam maximus risus, vel sed
            vehicula.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h5 className="major"><a href="https://github.com/damilarelana/matlab" target="_blank" rel="noopener noreferrer">Convex Optimization with Parahermitians</a></h5>
          <span className="image main">
            <img src={MobiusStrip} alt="" />
          </span>
          <p>
          An <a href="https://www.sciencedirect.com/science/article/pii/S016769111100140X"> academic paper </a> on an economic parameterization for positive parahermitian matrix functions to 
          obtain a state-space solution for μ-analysis, via reformulation as a convex optimization problem. 
          It avoids the use of a frequency-dependent curve fitting approach, by Laguerre parameterization and spectral factorization 
          within a LMI framework. The algorithm gives a frequency-independent μ upper-bound with less number of decision variables, 
          a path to obtain μ without Matlab's prioprietary mu, and an advantage of less computational time. Two Matlab implementations demonstrate the 
          algorithm's effectiveness. [Matlab, Robust Control, Algorithm]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/goMicroservices" target="_blank" rel="noopener noreferrer">Maths Operator Microservice</a></h5>
          <span className="image main">
            <img src={goMicroservice} alt="" />
          </span>
          <p>
          A microservice implementation that exposes a Maths operator: Addition [for single digits], Average/Maximum/Minimum/Summation/Sorted computes [for numeric arrays], via REST API endpoints and 
          deployed to a Kubernetes cluster. [Microservices, Go, gRPC, Protobuf, Docker, Kubernetes]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/HandwrittenDigitsClassification" target="_blank" rel="noopener noreferrer">Handwritten Digits Classification</a></h5>
          <span className="image main">
            <img src={DigitsClassifier} alt="" />
          </span>
          <p>
          A classification algorithm that applies scikit-learn to the problem of optical character recognition of hand-written numerical digits. 
          The code focuses on identifying the numerical character in the image, without attempting to locate the image itself. 
          Test/validation data comes via scikit-learn's preformatted digits. 
          The approach uses a test harness to initially spot-check different classification models: LR, LDA, KNN, NB, SVM, CART, before making a final prediction based on the KNN model. 
          [K-Nearest Neighbors, Seaborn, Python, Numpy, Matplotlib, Pandas, Scikit-Learn]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/goCodeYourOwnAdventure" target="_blank" rel="noopener noreferrer">Game: Text Adventure</a></h5>
          <span className="image main">
            <img src={goCYOA} alt="" />
          </span>
          <p>
          A simple Golang implementation of a text-based adventure game:
            * in which a player is able to choose alternative paths
            * that renders to a webserver base UI
            * uses flags (`-port`, `-file`, `-switch`, `-template`) for user defined attributes
            * while implementing the `functional options` design pattern
          [Go, Command-Line Flags, JSON, Url Parser, HTML, CSS, Design Pattern]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/IrisFlowersClassification" target="_blank" rel="noopener noreferrer">Iris Flowers Classification</a></h5>
          <span className="image main">
            <img src={DMesh} alt="" />
          </span>
          <p>
          A simple Scikit-Learn implementation of Iris Flower classification i.e. supervised learning, that uses the `iris flowers` within the `iris dataset`. 
          The approach uses a test harness to initially spot-check different classification models: LR, LDA, KNN, NB, SVM, CART, before making a final prediction based on the KNN model. 
          [Iris Dataset, K-Nearest Neighbors, Seaborn, Python, Numpy, Matplotlib, Pandas, Scikit-Learn]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/goGraphQLGoogleAppEngine" target="_blank" rel="noopener noreferrer">GraphQL Server</a></h5>
          <span className="image main">
            <img src={PartialPolar} alt="" />
          </span>
          <p>
          A golang implementation of GraphQL server, deployed to Google App Engine serverless platform. 
          The GraphQL server is hosted at https://graphqlserver-259904.appspot.com/graphql. 
          The server allows for mutations (to create users/posts) and query (of users/posts) via either a Post or Get method. [GraphQL, Go, Google App Engine]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/ANN-GradientDescent" target="_blank" rel="noopener noreferrer">ANN - Gradient Descent</a></h5>
          <span className="image main">
            <img src={DMesh} alt="" />
          </span>
          <p>
          An algorithm that implements gradient descent and backpropagation training methods, from first principles - without 
          recourse to frameworks. The code performs training and accuracy prediction using the MNIST dataset. [Python, Numpy, Matplotlib, Scikit-Learn]
          </p>          
          <h5 className="major"><a href="https://github.com/damilarelana/goUrlShortener" target="_blank" rel="noopener noreferrer">Url Shortener</a></h5>
          <span className="image main">
            <img src={DMesh} alt="" />
          </span>
          <p>
          A simple Golang implementation of a Url Shortener that:
            * examines path of incoming request
            * determines if re-direction is required
            * uses flags (`-yaml`, `-json`, `-sql`) to source the required content from files or database instead of inline strings
          [Go, PostgreSQL, Command-Line Flags, YAML, JSON, Url Parser, ]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/goStatsMonitor" target="_blank" rel="noopener noreferrer">Youtube Stats Monitor</a></h5>
          <span className="image main">
            <img src={PartialPolar} alt="" />
          </span>
          <p>
          A monitoring application that displays a Youtube channel's statistic [Views count, Subscribers count, Videos count]. 
          It consists of a simple frontend that is feed with JSON data by a backend through a websocket connection. 
          The implementation leverages <a href="https://www.googleapis.com/youtube/v3/channels">Youtube's API</a>, 
          Docker image [generated via Dockerfile] and endpoints implemented using <a href="http://www.gorillatoolkit.org/pkg/mux">Gorilla Mux</a>. 
          [Go, API, Docker, Websockets, JS, JSON, Gorilla Mux]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/goQuiz" target="_blank" rel="noopener noreferrer">Quiz (Maths Question/Answers)</a></h5>
          <span className="image main">
            <img src={PartialPolar} alt="" />
          </span>
          <p>
          A simple Golang implementation of a Quiz that:
            * asks a user simple maths questions
            * validates their answer
            * computes their % correct answer
            * times the user in between questions
            * sources both the question and answers from a CSV file
          [Go, GoRoutines/Channels, Command-Line Flags, CountDown Timer, CSV]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/goGravatar" target="_blank" rel="noopener noreferrer">Gravatar Image Service</a></h5>
          <span className="image main">
            <img src={DMesh} alt="" />
          </span>
          <p>
          A gRPC based web service that consumes Gravatar's API to generates URLs mapped to a 
          globally unique email address and personalized avatar. Test coverage only covers the `hasher` package. 
          [Go, gRPC, Protocol Buffers, Gravatar]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/FaceRecognition-Primer" target="_blank" rel="noopener noreferrer">Face Recognition</a></h5>
          <span className="image main">
            <img src={PartialPolar} alt="" />
          </span>
          <p>
          A simple python implementation of human facial recognition, based on Python Imaging Library (PIL) that uses pre-prepared test/stock images at runtime. [Python, PIL (Python Imaging Library)]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/GaussianModel-Primer" target="_blank" rel="noopener noreferrer">Gaussian Mixture Model</a></h5>
          <span className="image main">
            <img src={PartialPolar} alt="" />
          </span>
          <p>
          A simple scikit-learn implementation of a Gaussian Mixture Model i.e. a primer about unsupervised learning: clustering, based on the Iris dataset. [Python, Numpy, Matplotlib, Seaborn, Scikit-Learn]
          </p>          
          <h5 className="major"><a href="https://github.com/damilarelana/PrincipalComponentAnalysis-Primer" target="_blank" rel="noopener noreferrer">Principal Component Analysis</a></h5>
          <span className="image main">
            <img src={DMesh} alt="" />
          </span>
          <p>
          A simple scikit-learn implementation of Principal Component Analysis i.e. a primer about unsupervised learning: dimensionality reduction, based on the Iris dataset. [Python, Numpy, Matplotlib, Seaborn, Scikit-Learn]
          </p>          
          <h5 className="major"><a href="https://github.com/damilarelana/NaiveBayes-Primer" target="_blank" rel="noopener noreferrer">Naive Bayes</a></h5>
          <span className="image main">
            <img src={PartialPolar} alt="" />
          </span>
          <p>
          A simple scikit-learn implementation of Naive Bayes i.e. a primer about supervised learning: classification, based on the Iris dataset. [Python, Numpy, Matplotlib, Seaborn, Scikit-Learn]
          </p>
          <h5 className="major"><a href="https://github.com/damilarelana/LinearRegression-Primer" target="_blank" rel="noopener noreferrer">Linear Regression</a></h5>
          <span className="image main">
            <img src={DMesh} alt="" />
          </span>
          <p>
          A simple scikit-learn implementation of Linear Regression i.e. a primer about supervised learning: regression analysis, using randomly generated training and testing data. [Python, Numpy, Matplotlib, Seaborn, Scikit-Learn]
          </p>          
          <h5 className="major"><a href="https://github.com/damilarelana/sorogbesi" target="_blank" rel="noopener noreferrer">Sorogbesi</a></h5>
          <span className="image main">
            <img src={PartialPolar} alt="" />
          </span>
          <p>
          A simple Chat application based on a React (frontend) and Go (backend) that communicate via websockets. 
          [React, Websocket, Go REST API, Go Modules, Go Routines, Gorilla Mux, Node Sass, Dockerfile]
          </p>

          <h5 className="major"><a href="https://github.com/damilarelana/flaskiblog" target="_blank" rel="noopener noreferrer">Awokogbon</a></h5>
          <span className="image main">
            <img src={DMesh} alt="" />
          </span>
          <p>
          A bare-bones Blog with authentication [login, logout, register] features and tests coverage. 
          [Flask, Pytest, SQLite, HTML, CSS, Jinja2]
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
          An adaptable Problem-Solver who delivers on tech product milestones, built atop 14yrs+ experience spanning engineering leadership, individual contribution (peer-review research, coding, data), and L&D (mentorship, coaching, planning). 
          I'm focused on machine learning, computer vision, and quantum computing - with Python, Pytorch, Numpy, Matlab, Scikit-Learn, Golang etc., to provide solutions at the intersection of theory, design patterns and real-world applications: autonomous systems, precision medical imaging and astronomy.
          </p>

          <p>
          I'm adept at pushing limits via stretch goals with strategic business OKRs within global high-performance teams - <a href="https://www.linkedin.com/in/damilare-lana-4902698b/"> LinkedIn </a>.

          With great power there must also come great responsibility (Uncle Ben, Amazing Fantasy #15).
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <address>
          <a href="mailto:damilare@lana.ng">damilare@lana.ng</a><br/>
          </address>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
